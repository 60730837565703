import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('statistics-card-with-images',{staticClass:"fill-height",attrs:{"icon":_vm.ratingsOptions.icon,"avatar":_vm.ratingsOptions.avatar,"avatar-width":_vm.ratingsOptions.avatarWidth,"chip-color":_vm.ratingsOptions.chipColor,"chip-text":_vm.ratingsOptions.chipText,"statistics":_vm.totalAmountList,"stat-title":_vm.ratingsOptions.statTitle}})],1),_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c(VCard,{staticClass:"fill-height"},[_c(VCardTitle,[_vm._v("Suchen")]),_c(VCardText,[_c('div',{staticClass:"d-flex align-center pb-5"},[_c(VTextField,{staticClass:"channel-list-search me-3",attrs:{"dense":"","outlined":"","hide-details":"","clearable":"","placeholder":"Werbemittel suchen"},model:{value:(_vm.filter.searchString),callback:function ($$v) {_vm.$set(_vm.filter, "searchString", $$v)},expression:"filter.searchString"}})],1)])],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"id":"channel-list"}},[_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('router-link',{attrs:{"to":'/werbemittel/erstellen'}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"accent"}},[_c(VIcon,{staticClass:"pr-2",attrs:{"size":"30"}},[_vm._v(_vm._s(_vm.icons.mdiPencilRuler))]),_c('span',[_vm._v("Werbemittel erstellen")])],1)],1)],1)])],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listAdMaterials,"options":_vm.options,"server-items-length":_vm.totalAmountList,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loading,"search":_vm.filter.searchString,"no-data-text":"Keine Einträge vorhanden","no-results-text":"Ihre Suche ergab keinen Treffer","footer-props":{
          itemsPerPageText: 'Anzahl pro Seite',
          itemsPerPageAllText: 'Alle',
          pageText: '{0}-{1} von {2}',
          itemsPerPageOptions: [5,10,25,50,-1]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Möchten Sie "+_vm._s(_vm.deleteItem.titel)+" wirklich löschen?")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDelete}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.deleteAdMaterialConfirm}},[_vm._v(" Löschen ")]),_c(VBtn,{attrs:{"color":"secondary","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Abbrechen ")]),_c(VSpacer)],1)],1)],1)]},proxy:true},{key:"item.idPtWerbemittel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" #"+_vm._s(item.idPtWerbemittel)+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.dateiVorderseite.file.size > 0 && item.dateiRueckseite.file.size > 0)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('a',{attrs:{"href":("/api/werbemittel/?aktion=showAdMaterialPDF&mainData[idPtWerbemittel]=" + (item.idPtWerbemittel)),"target":"_blank"}},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilRuler)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Werbemittel Vorschau")])]):_vm._e(),(!item.entityLocked)?[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.typ=='erstellmaske')?_c('router-link',{attrs:{"to":'/werbemittel/editieren/'+item.idPtWerbemittel}},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)],1):_c('router-link',{attrs:{"to":'/werbemittel/hochladen/'+item.idPtWerbemittel}},[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteAdMaterial( item )}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Löschen")])])]:[_c('info-popup',{attrs:{"title":"Hinweis: Werbemittel gesperrt"}},[_c('p',[_vm._v(" Das von Ihnen ausgewählte Werbemittel ist derzeit gesperrt, da es in einer aktiven Kampagne verwendet wird. ")]),_c('p',[_vm._v(" Änderungen an diesem Werbemittel sind nicht möglich, solange die Kampagne nicht abgeschlossen, storniert oder aufgrund eines Fehlers abgebrochen wurde. ")]),_c('p',[_vm._v(" Sobald die Kampagne ihren endgültigen Status erreicht hat, wird das Werbemittel wieder zur Bearbeitung freigegeben. ")])])]],2)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }